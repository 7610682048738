export default class Client {
  value: string;

  constructor(value?:string) {
    this.value = (value === undefined ? '' : value);
  }

  static clone(source: Client): Client {
    return new Client(source.value);
  }
}
