import TypeJpDate from '@/model/type/TypeJpDate';
import UserID from '../UserID';
import Client from './Client';
import ClientID from './ClientID';
import KeiyakuID from './KeiyakuID';
import Name from './Name';
import WorkListID from './WorkListID';
import WorkType from './WorkType';
import WorkTypeID from './WorkTypeID';

/**
 * 経歴情報
 */
export default class HistoryInfoClass {
  /** ユーザーID */
  userId: UserID;
  /** workListID */
  workListId: WorkListID;
  /**  */
  name: Name;
  /** クライアント名 */
  client: Client;

  worktype: WorkType;

  worktypeId: WorkTypeID;

  startDate: TypeJpDate;

  endDate: TypeJpDate;
  /** v-for用シーケンス */
  seq: number = 0;

  constructor(
    userId?: UserID,
    workListId?: WorkListID,
    keiyakuId?: KeiyakuID,
    name?: Name,
    client?: Client,
    clientId?: ClientID,
    worktype?: WorkType,
    worktypeId?: WorkTypeID,
    startDate?: TypeJpDate,
    endDate?: TypeJpDate,
  ) {
    this.userId = (userId === undefined ? new UserID() : userId);
    this.workListId = (workListId === undefined ? new WorkListID() : workListId);
    this.name = (name === undefined ? new Name() : name);
    this.client = (client === undefined ? new Client() : client);
    this.worktype = (worktype === undefined ? new WorkType() : worktype);
    this.worktypeId = (worktypeId === undefined ? new WorkTypeID() : worktypeId);
    this.startDate = (startDate === undefined ? TypeJpDate.getToday() : startDate);
    this.endDate = (endDate === undefined ? TypeJpDate.getToday() : endDate);
  }

  public static cloneList(source:HistoryInfoClass[]):HistoryInfoClass[] {
    const result : HistoryInfoClass[] = [];
    source.forEach((s) => {
      result.push(HistoryInfoClass.clone(s));
    });
    return result;
  }

  public static clone(source: HistoryInfoClass): HistoryInfoClass {
    const result = new HistoryInfoClass();
    result.userId = UserID.clone(source.userId);
    result.workListId = WorkListID.clone(source.workListId);
    result.name = Name.clone(source.name);
    result.client = Client.clone(source.client);
    result.worktype = WorkType.clone(source.worktype);
    result.worktypeId = WorkTypeID.clone(source.worktypeId);
    result.startDate = TypeJpDate.clone(source.startDate);
    result.endDate = TypeJpDate.clone(source.endDate);
    return result;
  }
}
