export default class WorkType {
  value: string;

  constructor(value?: string) {
    this.value = (value === undefined ? '' : value);
  }

  static clone(source: WorkType): WorkType {
    return new WorkType(source.value);
  }
}
