
import { Component, Vue, Prop } from 'vue-property-decorator';
import HistoryInfoClass from '@/model/user/history/HistoryInfoClass';
import HistoryEdit from '@/components/User/History/HistoryEdit.vue';
import HistoryApi from '@/module/api/HistoryApi';

@Component({
  components: {
    HistoryEdit
  }
})
export default class HistoryInfo extends Vue {
  edit = false;
  @Prop() history!:HistoryInfoClass;
  update():void{
    this.edit = true;
  }
  copy():void{
    this.$emit('copy', this.history);
  }
  deletehistory():void{
    HistoryApi.delete(this.history);
    this.$emit('deletehistory', this.history);
  }
  cancel(updateHistory:HistoryInfoClass):void{
    this.edit = false;
    if (updateHistory) {
      this.history = updateHistory;
    }
  }
}

