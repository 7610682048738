
import { Vue, Component, Prop } from 'vue-property-decorator';
import HistoryInfo from '@/components/User/History/HistoryInfo.vue';
import HistoryEdit from '@/components/User/History/HistoryEdit.vue';
import HistoryApi from '@/module/api/HistoryApi';
import HistoryInfoClass from '@/model/user/history/HistoryInfoClass';
import UserID from '@/model/user/UserID';
import BackButton from '@/components/Parts/BackButton.vue';

@Component({
  components: {
    HistoryInfo,
    HistoryEdit,
    BackButton
  }
})
export default class HistoryRegister extends Vue {
  cnt = 0;
  userId!:UserID;
  history:HistoryInfoClass[] = [];
  appendedHistory:HistoryInfoClass[] = [];
  @Prop() historyCopy!:HistoryInfoClass[];
  sequence = 0;

  mounted():void{
    HistoryApi.findAll(this.$route.params.userId).then((res) => {
      this.history = res.data;
      this.userId = this.history[0].userId;
    });
  }
  historyAdd(): void {
    const add = new HistoryInfoClass();
    // eslint-disable-next-line no-plusplus
    add.seq = this.sequence++;
    this.appendedHistory.push(add);
  }
  copy(copybase: HistoryInfoClass):void{
    this.appendedHistory.push(copybase);
  }
  add(addhistory:HistoryInfoClass):void{
    this.history.push(addhistory);
  }
  deletehistory(deletehistory:HistoryInfoClass):void{
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.history.length; i++) {
      if (this.history[i].workListId.value === deletehistory.workListId.value) {
        this.history.splice(i, 1);
        break;
      }
    }
  }
}
