
import { Vue, Component, Prop } from 'vue-property-decorator';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import HistoryInfoClass from '@/model/user/history/HistoryInfoClass';
import HistoryApi from '@/module/api/HistoryApi';
import UserUpdateItemInfo from '@/model/user/UserUpdateItemInfo';
import UpdateFields from '@/model/user/UpdateFields';
import UserApi from '@/module/api/UserApi';

@Component({
  components: {
    InputJpDate
  }
})
export default class HistoryEdit extends Vue {
  openEdit :boolean = true;
  @Prop({ default: false }) edit ?:boolean;
  @Prop() historyBase!:HistoryInfoClass;
  editHistory:HistoryInfoClass = new HistoryInfoClass();
  userUpdateItemList:UserUpdateItemInfo[]=[];

  mounted(): void {
    this.editHistory = HistoryInfoClass.clone(this.historyBase);
  }

  /** 新規登録キャンセル */
  cancel():void{
    this.openEdit = false;
  }
  /** 編集キャンセル */
  cancelEdit(updateHistory:HistoryInfoClass):void{
    this.$emit('cancel', updateHistory);
  }
  /** 更新登録 */
  editSave():void{
  //    this.$set(this.historyBase, 'userId', '110');
    HistoryApi.update(this.editHistory).then((res) => {
      const updateHistory:HistoryInfoClass = res.data;
      this.cancelEdit(updateHistory);
    });
  }
  /** 新規登録 */
  save():void{
    this.$set(this.editHistory.userId, 'value', this.$route.params.userId);
    HistoryApi.save(this.editHistory).then((res) => {
      const updateHistory:HistoryInfoClass = res.data;
      const userUpdateItem:UserUpdateItemInfo = new UserUpdateItemInfo(this.editHistory.userId, new UpdateFields('history'));
      this.userUpdateItemList.push(userUpdateItem);
      UserApi.saveUpdateItem(this.userUpdateItemList);

      this.$emit('add', updateHistory);
    });
    this.openEdit = false;
  }
}
