import { AxiosPromise } from 'axios';
import HistoryInfoClass from '@/model/user/history/HistoryInfoClass';
import axios from '../AxiosBase';

export default {
  findAll(userid:any):AxiosPromise<any> {
    return axios.get(`history/all/${userid}`);
  },
  findByDate(startDate:string, endDate:string):AxiosPromise<any> {
    return axios.get(`history/byDate?startDate=${startDate}&endDate=${endDate}`);
  },
  privFindByDate(userId:string, startDate:string, endDate:string):AxiosPromise<any> {
    return axios.get(`admin/history/byDate/${userId}?startDate=${startDate}&endDate=${endDate}`);
  },
  save(historyInfo:HistoryInfoClass):AxiosPromise<any> {
    return axios.post('history/save', historyInfo);
  },
  update(historyInfo:HistoryInfoClass):AxiosPromise<any> {
    return axios.post('history/update', historyInfo);
  },
  delete(historyInfo:HistoryInfoClass):AxiosPromise<any> {
    return axios.post('history/delete', historyInfo);
  }
};
